export const LANGUAGES = [
  { locale: "en", name: "English" },
  { locale: "de", name: "Deutsch" },
  { locale: "it", name: "Italiano" },
  { locale: "fr", name: "Français" },
  // add new languages here
]
/*
,
  { locale: "rm", name: "Rumantsch" }
*/